import { Grid } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEffect, useState } from 'react';

import { SpecificationItem } from '../../../view/admin/page/specification/parts/formSelection/SpecificationFormSelection';
import { SpecificationFormSelectionDomain } from '../../../view/admin/page/specification/parts/formSelection/SpecificationFormSelectionDomain';
import { ApplicationDetailsType } from '../../../view/user/page/application/info/store/ApplicationDetailsType';
import {
  IApplicationInfoDetailsFormProperties
} from '../../../view/user/page/application/info/parts/application/ApplicationInfoDetailsForm';
import { ApplicationDetailDomain } from '../../../view/user/page/application/info/store/ApplicationDetailDomain';
import { getProtectionLevel, protectLevels, specificationsNames } from '../protectLevel/protectLevelSpecifications';
import { SpecificationCategoryType } from '../../../service/specification/category/ISpecificationCategoryModelConditionMap';
import { FormTextArea } from '../../../view/design/form/FormTextArea';

const getCustomFieldsWithOptions = (domain: ApplicationDetailDomain) => {
  const result = domain.ui.editSpecificationsCategories.list.map((specificationCategory) => {
    if (specificationCategory.categoryType === SpecificationCategoryType.specificationCategoryText) {
      const currentSavedField = domain.ui.specificationsTextFieldsValues.list.find(value => value.categoryId === specificationCategory.id)
      const textFieldName = specificationCategory.isActive ? specificationCategory.name : specificationCategory.name + ' (неактивен)'
      const currentValue = currentSavedField ? currentSavedField.name : ''
      const isHaveError =
        domain.ui.specificationsTextFieldsErrors.value.find(
          errorId => errorId === specificationCategory.id
        ) || null

      return (
        specificationCategory.isActive ? {
          name: specificationCategory.name,
          component: (
            <FormTextArea
              value={currentValue || ''}
              onChange={(newValue) => {
                domain.onEditCategoryTextField({ category: specificationCategory, newValue })
              }}
              label={textFieldName || ''}
              required={specificationCategory.conditionsTextMap?.isRequired}
              paddingSizing={1}
              size={'small'}
              autoRows={true}
              minRows={2}
              errorMessage={isHaveError ? 'Обязательно к заполнению' : null}
            />
          )
        } : currentValue
          ? {
            name: specificationCategory.name,
            component: (
              <FormTextArea
                value={currentValue || ''}
                onChange={(newValue) => {
                  domain.onEditCategoryTextField({ category: specificationCategory, newValue })
                }}
                label={specificationCategory.name + ' (неактивен)' || ''}
                required={specificationCategory.conditionsTextMap?.isRequired}
                paddingSizing={1}
                size={'small'}
                autoRows={true}
                minRows={2}
                errorMessage={isHaveError ? 'Обязательно к заполнению' : null}
              />
            )
          }
          : <></>
      )
    }
  }).filter(item => item !== undefined)
  return result || [];
};

const getSpecificationsWithOptions = (domain, specificationDomain) => {
  const result = domain.ui.editSpecificationsCategories.list.map((specificationCategory) => {
    if (specificationCategory.name?.toLocaleLowerCase() === 'уровень защищенности пдн') {
      return {
        name: specificationCategory.name,
        component: (
          <SpecificationItemExtended
            disabled={true}
            domain={domain}
            specificationCategory={specificationCategory}
            specificationDomain={specificationDomain}
          />
        ),
      };
    }

    return {
      name: specificationCategory.name,
      component: (
        <SpecificationItemExtended
          domain={domain}
          specificationCategory={specificationCategory}
          specificationDomain={specificationDomain}
        />
      ),
    };
  });
  return result || [];
};

export interface IApplicationInfoDetailsFormExtendedProperties extends IApplicationInfoDetailsFormProperties {
  domain: ApplicationDetailDomain;
  isHaveAnyCombineSpecs?: number;
}

const cleanProtectLevel = ({ domain, isHaveAnyCombineSpecs }: IApplicationInfoDetailsFormExtendedProperties) => {
  const allLevels = domain.ui.editSpecifications.list
    .filter((specification) => {
      return protectLevels.includes(specification.name.toLocaleLowerCase());
    })
    .map((specification) => specification.id);

  let isNeedClean = false;
  domain.ui.editApplication.entity.specificationsIds?.forEach((item) => {
    allLevels.includes(item) && (isNeedClean = true);
  });

  if (isNeedClean && !isHaveAnyCombineSpecs) {
    domain.ui.editApplication.setEntity({
      ...domain.ui.editApplication.entity,
      specificationsIds: domain.ui.editApplication.entity.specificationsIds
        ? [...domain.ui.editApplication.entity.specificationsIds.filter((item) => !allLevels.includes(item))]
        : [],
    });
  }
};


const getProtectLevel = ({ domain }: IApplicationInfoDetailsFormProperties) => {
  const specifications = domain.ui.editApplication.entity?.specificationsIds?.map((specificationId) => {
    const specification = domain.ui.editSpecifications.list.find((specification) => {
      return specification.id === specificationId;
    });
    if (specification && specificationsNames?.includes(specification.name.toLocaleLowerCase())) {
      return specification.name.toLocaleLowerCase();
    }
  });
  const level = getProtectionLevel(specifications);

  const allLevels = domain.ui.editSpecifications.list.filter((specification) => {
    return protectLevels.includes(specification.name.toLocaleLowerCase())
  });

  cleanProtectLevel({ domain, isHaveAnyCombineSpecs: level });

  if (level !== 0) {
    const result = allLevels.find((specification) => specification.name.toLocaleLowerCase() === `уз ${level}`);
    if (result?.id != null && !domain.ui.editApplication.entity.specificationsIds?.includes(result.id)) {
      domain.ui.editApplication.setEntity({
        ...domain.ui.editApplication.entity,
        specificationsIds: domain.ui.editApplication.entity.specificationsIds ? [...domain.ui.editApplication.entity.specificationsIds, result.id] : [],
      });
    }
  }
};

export const EditModeListsExtended = observer(({ domain }: IApplicationInfoDetailsFormProperties) => {
  const [specificationDomain] = useState<SpecificationFormSelectionDomain>(new SpecificationFormSelectionDomain());
  const customFields = getCustomFieldsWithOptions(domain);
  const specifications = getSpecificationsWithOptions(domain, specificationDomain);
  const resultArray = [...customFields, ...specifications];
  // resultArray.sort(sortCallBack);

  useEffect(() => {
    getProtectLevel({ domain });
  }, [domain.ui.editApplication.entity.specificationsIds, domain]);

  return (
    <>
      {domain.ui.type.value === ApplicationDetailsType.entity ? (
        <Grid item key="allFieldsData">
          {resultArray.map((item) => item.component)}
        </Grid>
      ) : (
        <Grid item key="allFieldsData">
          {resultArray.map((item) => item.component)}
        </Grid>
      )}
    </>
  );
});

export const SpecificationItemExtended = observer(({ domain, specificationCategory, specificationDomain, disabled = false }) => {
  return (
    <SpecificationItem
      disabled={disabled}
      onChange={(specificationIds) => domain.onChangeApplicationSpecifications(specificationIds)}
      selectedSpecificationsIds={domain.ui.editApplication.entity.specificationsIds || []}
      specifications={domain.ui.editSpecifications.list}
      domain={specificationDomain}
      specificationCategory={specificationCategory}
      key={specificationCategory.id}
    />
  );
});
